@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  @apply bg-white font-opensans font-normal text-sm 2xl:text-base pt-[72px] lg:pt-[96px] 2xl:pt-[120px]
}

.transition-alls {
  @apply transition-all ease-in-out duration-200
}

a {
  @apply transition-alls
}

.container {
  @apply px-[1rem] sm:px-[2rem] md:px-[3rem]
}

.header {
  @apply w-full bg-white fixed top-0 z-40
}

.header img {
  @apply h-10 lg:h-12 2xl:h-16 transition-alls
}

.header.small img {
  @apply h-10 lg:h-9 2xl:h-12
}

.menu {
  @apply hidden lg:flex flex-row space-x-4 items-center
}

.menu li {
  @apply py-2.5 px-2 xl:px-4 2xl:py-4
}

.menu li:last-child {
  @apply pr-0
}

.menu li a {
  @apply hover:font-bold hover:text-purple-secondary text-sm 2xl:text-base
}

section {
  @apply py-16 md:py-20 2xl:py-28 z-10
}

.h1 {
  @apply font-poppins text-xl sm:text-4xl md:text-8xl lg:text-10xl xl:text-12xl 2xl:text-16xl
}
.h2 {
  @apply font-poppins font-bold text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-5xl
}
.h3 {
  @apply font-poppins text-base md:text-base lg:text-base 2xl:text-xl
}

footer {
  @apply bg-purple-secondary p-0
}
.btn {
  @apply rounded-lg py-3 px-10 2xl:py-3.5 2xl:px-14 text-white bg-purple-primary hover:bg-purple-secondary text-center font-semibold w-full max-w-[180px] lg:max-w-[200px] 2xl:max-w-[260px]
}
.mySwiperThumb .swiper-slide {
  @apply transition-alls border-b-[5px] md:border-b-[8px] lg:border-b-[10px] 2xl:border-b-[14px] border-transparent hover:border-turquoise-primary;
}
.mySwiperThumb .swiper-slide-thumb-active {
  @apply border-turquoise-primary;
}

.ListInvest {
  @apply text-left 
}

.ListInvest li {
  @apply relative pl-4 lg:pl-5 2xl:pl-7 mb-1
}

.ListInvest li:before {
  content: '';
  @apply w-1 h-1 top-2 lg:w-2 lg:h-2 lg:top-1.5 2xl:top-2.5 absolute left-0 bg-purple-primary
}

.MenuBlog li a {
  @apply relative pl-4 md:pl-5 mb-1 block hover:text-purple-primary hover:font-bold
}

.MenuBlog li a:before {
  content: '';
  @apply w-1 h-1 top-2 lg:top-2 xl:top-2.5 2xl:w-1.5 2xl:h-1.5 2xl:top-2.5 absolute left-0  bg-purple-primary
}

[type='text'], [type='email'], [type='url'], [type='password'], [type='number'], [type='date'], [type='datetime-local'], [type='month'], [type='search'], [type='tel'], [type='time'], [type='week'], [multiple], textarea, select {
  @apply text-sm 2xl:text-base
}

/* Position and sizing of burger button */
.bm-burger-button {
  @apply hidden
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  @apply hidden
}

/* Color/shape of close button cross */
.bm-cross {
  @apply bg-white top-2 -left-2.5
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  @apply fixed w-full h-full top-0 z-40
}

/* General sidebar styles */
.bm-menu {
  @apply bg-purple-primary py-0 px-0
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  @apply fill-gray-700
}

/* Wrapper for item list */
.bm-item-list {
  @apply relative w-full
}

/* Individual item */
.bm-item {
  @apply text-white border-0 outline-none border-b border-purple-secondary
}

.bm-morph-shape {
  @apply fill-purple-primary
}

/* Styling of overlay */
.bm-overlay {
  @apply bg-black bg-opacity-30 top-0 z-40
}

.swiper-carousel-prev {
  @apply cursor-pointer bg-no-repeat bg-contain bg-center bg-[url('./images/flecha-carrucel-izquierda.svg')] hover:bg-[url('./images/flecha-carrucel-izquierda-hover.svg')] absolute left-4 lg:left-7 h-10 w-[15px]
}
.swiper-carousel-next {
  @apply cursor-pointer bg-no-repeat bg-contain bg-center bg-[url('./images/flecha-carrucel-derecha.svg')] hover:bg-[url('./images/flecha-carrucel-derecha-hover.svg')] absolute right-4 lg:right-7 h-10 w-[15px]
}

.Sliders {
  @apply h-[calc(100vh_-_72px)] lg:h-[calc(100vh_-_96px)] 2xl:h-[calc(100vh_-_120px)]
}

.Sliders .swiper-slide .swiper-lazy {
  @apply transition-all ease-in-out duration-5000 absolute bg-cover bg-center bg-no-repeat w-full h-full z-10 scale-105
}
.Sliders .swiper-slide.swiper-slide-active .swiper-lazy {
  @apply scale-100
}

.prose-all {
  @apply 
  prose-sm 
  prose-p:2xl:text-base
  prose-p:2xl:leading-normal
  prose-h1:font-bold
  prose-h2:font-bold
  prose-h3:font-bold
  prose-h4:font-bold
  prose-h1:font-poppins
  prose-h2:font-poppins
  prose-h3:font-poppins
  prose-h4:font-poppins
  prose-h1:text-purple-primary 
  prose-h2:text-purple-primary 
  prose-h3:text-purple-primary
  prose-h4:text-purple-primary
  prose-li:2xl:text-base
  prose-li:2xl:leading-normal
  prose-li:leading-normal
  prose-li:relative
  prose-li:before:w-1.5
  prose-li:before:h-1.5
  prose-li:before:bg-purple-primary
  prose-li:before:absolute
  prose-li:before:-left-3
  prose-li:before:top-2
  prose-a:text-purple-primary
  prose-a:hover:text-purple-secondary
  prose-a:underline
  prose-a:font-semibold
}

.paginate {
  @apply mt-24 flex justify-center items-center space-x-2
}
.paginate li {

}
.paginate li:not(.previous):not(.next) a {
  @apply py-3 px-4 text-white bg-purple-primary rounded-lg hover:bg-turquoise-primary
}
.paginate li.previous.disabled a,
.paginate li.next.disabled a {
  @apply opacity-30
}
.paginate li.previous a {
  @apply text-xl leading-none lg:text-3xl lg:leading-none px-2
}
.paginate li.next a {
  @apply text-xl leading-none lg:text-3xl lg:leading-none px-2
}

.paginate li.active a {
  @apply !bg-turquoise-primary
}
.paginate li.break {

}
.paginate li.next a {

}